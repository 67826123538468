import request from "@/utils/request";

/**
 * 是否收藏
 * @param {*} parameter
 * @returns
 */
export function userIsCollect(parameter) {
  return request({
    url: "/user/userCollect/isCollect",
    method: "get",
    params: parameter,
  });
}

/**
 * 收藏分页
 * @param {*} parameter
 * @returns
 */
export function userCollectPage(parameter) {
  return request({
    url: "/user/userCollect/page",
    method: "get",
    params: parameter,
  });
}

/**
 * 删除
 * @param {*} parameter
 * @returns
 */
export function userCollectRemove(parameter) {
  return request({
    url: "/user/userCollect/remove",
    method: "post",
    params: parameter,
  });
}

/**
 * 添加收藏
 * @param {*} parameter
 * @returns
 */
export function userCollectSave(parameter) {
  return request({
    url: "/user/userCollect/save",
    method: "post",
    data: parameter,
  });
}
