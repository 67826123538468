import md5 from "md5";
import Thumbor from "./thumbor-js-url-builder";
import apiKeys from "@/config/apiKeys";
import { ossURL } from "./request";
import storage from "store";
import { ACCESS_TOKEN } from "@/config/constant";

/**
 * 判断是否为微信
 */
export function isWechat() {
  const ua = navigator.userAgent.toLocaleLowerCase();
  return ua.indexOf("micromessenger") !== -1;
}

/**
 * 签名算法 - java接口
 * @param {*} obj
 * @param {*} secretKey
 * @returns
 */
export function getSign(obj, secretKey) {
  if (Object.prototype.toString.call(obj) === "[object Object]") {
    let str = "";
    const sortArr = Object.keys(obj).sort();
    sortArr.forEach((key) => {
      if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
        let value = obj[key];
        if (typeof value === "object") {
          if (Object.prototype.toString.call(value) === "[object Object]") {
            value = filterNullValue(value);
          }
          value = JSON.stringify(value);
        }
        str += key + "=" + value + "&";
      }
    });
    str = str + "secretKey=" + secretKey;
    return md5(str).toUpperCase();
  }
  return "";
}

/**
 * 去除{}中key值为空的项
 * @param {*} obj
 * @returns
 */
function filterNullValue(obj) {
  const newObj = {};
  const arr = Object.keys(obj);
  arr.forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      // console.log(key, obj[key])
      const value = obj[key];
      if (Object.prototype.toString.call(value) === "[object Object]") {
        newObj[key] = filterNullValue(value);
      } else {
        newObj[key] = value;
      }
    }
  });
  return newObj;
}

/**
 * 生成32位随机数的方法
 * @returns
 */
export function createNonce() {
  const arr = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  let str = "";
  const L = arr.length - 1;
  for (let i = 0; i < 32; i++) {
    str += arr[getRandomIntInclusive(0, L)];
  }
  return str;
}

/**
 * 得到一个两数之间的随机整数，包括两个数在内
 * @param {*} min
 * @param {*} max
 * @returns
 */
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值
}

/**
 * minIO 图片上传: 后端返回地址 => 前端展示地址
 * @param {*} url
 * @param {*} config
 * @returns
 */
export function buildThumborUrl(url, config) {
  // 是否返回安全地址
  const SECURITY = true;
  // 初始化Thumbor实例
  const thumbor = new Thumbor(apiKeys.imageSecurityKey, ossURL);

  if (!url || typeof url !== "string") {
    console.warn("The url is required and it should be a string");
    return;
  }

  config = config || {};

  if (config.width && isNaN(config.width)) {
    console.warn("The width should be a number");
    return;
  }
  if (config.height && isNaN(config.height)) {
    console.warn("The height should be a number");
    return;
  }

  const width = config.width || 800;
  const height = config.height || 0;

  if (SECURITY) {
    return thumbor.setImagePath(url).resize(width, height).buildUrl();
  }
  url = url.charAt(0) === "/" ? url.substring(1) : url;
  return `${ossURL}/unsafe/${width}x${height}/${url}`;
}

/**
 * 生成java接口所属的header参数
 * @param {*} params
 * @returns
 */
export function createHeaders() {
  // 上传header参数预处理
  const timestamp = Date.now();
  const nonce = createNonce();
  const signObj = {
    "X-validate-accessKey": apiKeys.accessKey,
    "X-validate-timestamp": timestamp,
    "X-validate-nonceStr": nonce,
  };
  const sign = getSign(signObj, apiKeys.secretKey);

  const token = storage.get(ACCESS_TOKEN);

  const auth = token ? "Bearer " + token : undefined;

  return {
    "X-validate-accessKey": apiKeys.accessKey,
    "X-validate-timestamp": timestamp,
    "X-validate-nonceStr": nonce,
    "X-validate-sign": sign,
    Authorization: auth,
  };
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
  let config = {
    id: id || "id",
    parentId: parentId || "parentId",
    childrenList: children || "children",
  };

  var childrenListMap = {};
  var nodeIds = {};
  var tree = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }

  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }

  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  return tree;
}

/**
 * file => base64
 * @param {*} file
 * @returns
 */
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

/**
 * 深拷贝
 * @param {*} source
 * @returns
 */
export function deepClone(source) {
  if (!source) return;
  let target;
  if (typeof source === "object") {
    // 根据source类型判断是新建一个数组还是对象
    target = Array.isArray(source) ? [] : {};
    // 遍历source，并且判断是source的属性才拷贝
    for (const key in source) {
      // source.hasOwnProperty(key)
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        if (typeof source[key] !== "object") {
          target[key] = source[key];
        } else {
          // 如果内部属性存在复杂数据类型，使用递归实现深拷贝
          target[key] = deepClone(source[key]);
        }
      }
    }
  } else {
    target = source;
  }
  return target;
}

/**
 * 防抖函数
 * @param {*} fn
 * @param {*} delay
 * @returns
 */
export function debounce(fn, delay = 300) {
  let timer = null;
  return function () {
    const ctx = this;
    const args = arguments;
    if (timer) clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(ctx, args);
    }, delay);
  };
}

/**
 * 节流函数
 * @param {*} fn
 * @param {*} delay
 * @returns
 */
export function throttle(fn, delay = 1000) {
  let timer = null;
  return function () {
    const ctx = this;
    const args = arguments;
    if (!timer) {
      timer = setTimeout(function () {
        fn.apply(ctx, args);
        timer = null;
      }, delay);
    }
  };
}

/**
 * 获取图片
 * @param {*} image "xxx.jpg,xxx.png"
 * @returns
 */
export function getImageUrl(image, width, type = "multiple") {
  if (image) {
    let url;
    // 'single'
    if (type === "single") {
      url = image;
    }
    // multiple
    else {
      let urls = image.split(",");
      url = urls[0];
    }
    return buildThumborUrl(url, { width: width || 800 });
  }
}

/**
 * 获取加密的密码
 * @param {string} pwd
 * @returns {string} encryptedPwd
 */
export function getEncryptedPassword(pwd) {
  return window.btoa(pwd);
}
