import request from "@/utils/request";

/**
 * 商品分页
 * @param {*} parameter
 * @returns
 */
export function commodityPage(parameter) {
  return request({
    url: "/commodity/info/clientPage",
    method: "get",
    params: parameter,
  });
}

/**
 * 商品详情
 * @param {*} parameter
 * @returns
 */
export function commodityDetail(parameter) {
  return request({
    url: "/commodity/info/clientDetail",
    method: "get",
    params: parameter,
  });
}
