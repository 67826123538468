import request from "@/utils/request";

/**
 * 渠道用户验证码方式登录
 * @param {*} parameter
 * @returns
 */
export function userCodeLogin(parameter) {
  return request({
    url: "/user/login/distributor/code",
    method: "post",
    data: parameter,
  });
}

/**
 * 渠道用户账号密码方式登录
 * @param {*} parameter
 * @returns
 */
export function userPasswordLogin(parameter) {
  return request({
    url: "/user/login/distributor/password",
    method: "post",
    data: parameter,
  });
}

/**
 * 是否已注册
 * @param {*} parameter
 * @returns
 */
export function userIsRegistered(parameter) {
  return request({
    url: "/user/userDistributorInfo/isRegister",
    method: "get",
    params: parameter,
  });
}

/**
 * 用户注册
 * @param {*} parameter
 * @returns
 */
export function userRegister(parameter) {
  return request({
    url: "/user/userDistributorInfo/register",
    method: "post",
    data: parameter,
  });
}

/**
 * 获取用户信息
 * @param {*} parameter
 * @returns
 */
export function userInfo(parameter) {
  return request({
    url: "/user/userDistributorInfo/getUserInfo",
    method: "get",
    params: parameter,
  });
}

/**
 * 修改用户
 * @param {*} parameter
 * @returns
 */
export function userInfoUpdate(parameter) {
  return request({
    url: "/user/userDistributorInfo/updateUserInfo",
    method: "post",
    data: parameter,
  });
}

/**
 * 发送短信验证码
 * @param {*} parameter
 * @returns
 */
export function smsCode(parameter) {
  return request({
    url: "/sms/sendVerifyCode",
    method: "post",
    data: parameter,
  });
}

/**
 * 手机号通过验证码重置密码
 * @param {*} parameter
 * @returns
 */
export function userUpdatePassword(parameter) {
  return request({
    url: "/user/userInfo/updatePasswordByCode",
    method: "post",
    data: parameter,
  });
}

/**
 * 刷新token
 * @param {*} parameter
 * @returns
 */
export function refreshToken(parameter) {
  return request({
    url: "/system/refreshToken",
    method: "get",
    params: parameter,
  });
}

/**
 * 用户登出
 * @param {*} parameter
 * @returns
 */
export function adminLogout(parameter) {
  return request({
    url: "/system/logout",
    method: "get",
    params: parameter,
    logout: true,
    timeout: 5000,
  });
}
