const apis = {};
const modulesFn = require.context("./", false, /^\.\/(?!index).*\.js$/);
modulesFn.keys().forEach((key) => {
  const module = modulesFn(key);
  for (const api in module) {
    apis[api] = module[api];
  }
});

export default apis;
