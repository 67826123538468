<template>
  <div class="header-bar" :class="[bgColor, fixed ? 'fixed' : '']">
    <div class="inner">
      <div v-if="!noBack" class="left-btn" @click="backHandle">
        <RectLeft :color="backColor" />
      </div>
      <div class="title inaline">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { RectLeft } from "@nutui/icons-vue";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  onBack: {
    type: Function,
  },
  fixed: {
    type: Boolean,
    default: true,
  },
  bgColor: {
    type: String,
    default: "white", // white / orange / transparent
  },
  backColor: {
    type: String,
    default: "#333",
  },
  noBack: {
    type: Boolean,
    default: false,
  },
});
const backHandle = () => {
  if (props.onBack) props.onBack();
  else router.go(-1);
};
</script>

<style lang="scss" scoped>
.header-bar {
  height: var(--header-bar-height);
  overflow: hidden;
  &.fixed {
    .inner {
      position: fixed;
    }
  }
  &.white {
    .inner {
      background-color: #fff;
      border-bottom: 1px solid #eee;
    }
    .inner .title {
      color: #333333;
    }
  }
  &.transparent {
    height: 0;
    .inner {
      background-color: transparent;
      border-bottom: none;
    }
    .inner .title {
      color: #333333;
    }
  }
  &.orange {
    .inner {
      background-color: var(--nut-primary-color);
    }
    .inner .title {
      color: #fff;
    }
  }
  .inner {
    position: absolute;
    top: 0;
    margin: 0 auto;
    width: 375px;
    height: var(--header-bar-height);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 var(--header-bar-height);
    z-index: 99;
    overflow: hidden;
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
    }
    .left-btn {
      position: absolute;
      left: 0;
      top: 0;
      width: var(--header-bar-height);
      height: var(--header-bar-height);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
