import {
  Button,
  Searchbar,
  Image,
  Badge,
  Empty,
  Popup,
  Dialog,
  Input,
  InputNumber,
  Tabs,
  TabPane,
  InfiniteLoading,
  Toast,
  Notify,
  Swiper,
  SwiperItem,
} from "@nutui/nutui";

export default function useNutui(app) {
  app.use(Button);
  app.use(Searchbar);
  app.use(Image);
  app.use(Badge);
  app.use(Empty);
  app.use(InputNumber);
  app.use(Popup);
  app.use(Dialog);
  app.use(Input);
  app.use(Tabs);
  app.use(TabPane);
  app.use(InfiniteLoading);
  app.use(Toast);
  app.use(Notify);
  app.use(Swiper);
  app.use(SwiperItem);
}
