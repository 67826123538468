<template>
  <router-view v-slot="{ Component }">
    <keep-alive :max="10" :include="['SelectionMall']">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
