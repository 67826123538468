import router from "./router";
import useUserStore from "@/store/modules/user";
import { ACCESS_TOKEN } from "@/config/constant";
import storage from "store";
const allowList = [
  "/selection-mall",
  "/product-details",
  "/selection-list",
  "/my-center",
  "/account-check",
  "/sign-in",
  "/sign-up",
  "/password-setting",
];

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const token = storage.get(ACCESS_TOKEN);
  if (token) {
    if (!userStore.token) {
      userStore.getLocalInfo();
      if (!userStore.info?.userId) {
        userStore
          .getUserInfo()
          .then(() => {
            next();
          })
          .catch((err) => {
            console.error(err);
            next();
          });
      }
    } else {
      userStore
        .RefreshToken()
        .then(() => {
          //...
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          next();
        });
    }
  } else {
    if (allowList.indexOf(to.path) > -1) {
      next();
    } else {
      next({ path: "/" });
    }
  }
});

router.afterEach((to) => {
  document.title = to.meta.title;
});
