import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import useNutui from "./utils/useNutui";
import "./utils/flexible";
import "@nutui/nutui/dist/style.css";
import "./style/index.scss";
import "./permission";
import PageHeaderWrapper from "./components/PageHeaderWrapper.vue";
import PageLoading from "./components/PageLoading.vue";
import "./utils/common";
import directive from "./utils/directive";
import "@/api";
import { getImageUrl } from "./utils/tool";

const app = createApp(App);

app.component("PageHeaderWrapper", PageHeaderWrapper);
app.component("PageLoading", PageLoading);
app.config.globalProperties.$getImageUrl = getImageUrl;

app.use(store);
app.use(router);
useNutui(app);
directive(app);
app.mount("#app");
