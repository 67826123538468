/**
 * 文件相关接口
 */
import request, { baseURL } from "@/utils/request";

/**
 * 桶名
 * @param {*} parameter
 * @returns
 */
export const buckets = {
  commodity: "yunhe-scm-commodity-image", // 商品图
  order: "yunhe-scm-order-file", // 除财务外的单据图或文件
  supplier: "yunhe-scm-supplier-image", // 供应商信息图
  finance: "yunhe-scm-finance-file", // 财务单据图或文件
};

/**
 * 上传的文件最大值
 */
export const fileMax = {
  image: 3, // 图片类型3M
  file: 100, // 一般文件100M
};

/**
 * 文件上传
 * @param {*} parameter
 * @returns
 */
export const fileLoad = baseURL + "/minio-oss/fileUpload";

/**
 * 文件下载
 * @param {*} parameter
 * @returns
 */
export function downloadFile(parameter) {
  return request({
    url: "/minio-oss/download",
    method: "get",
    params: parameter,
    responseType: "blob",
  });
}
