import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/selection-mall",
  },
  {
    path: "/selection-mall",
    name: "/selection-mall",
    component: () => import("../views/mall/SelectionMall.vue"),
    meta: { title: "云和木玩商品展销中心" },
  },
  {
    path: "/product-details",
    name: "/product-details",
    component: () => import("../views/mall/ProductDetails.vue"),
    meta: { title: "商品详情" },
  },
  {
    path: "/selection-list",
    name: "/selection-list",
    component: () => import("../views/mall/SelectionList.vue"),
    meta: { title: "选品单" },
  },
  {
    path: "/my-center",
    name: "/my-center",
    component: () => import("../views/user/MyCenter.vue"),
    meta: { title: "我的" },
  },
  {
    path: "/my-collection",
    name: "/my-collection",
    component: () => import("../views/user/MyCollection.vue"),
    meta: { title: "我的收藏" },
  },
  {
    path: "/my-info",
    name: "/my-info",
    component: () => import("../views/user/MyInfo.vue"),
    meta: { title: "个人资料" },
  },
  {
    path: "/my-info-name",
    name: "/my-info-name",
    component: () => import("../views/user/MyInfoName.vue"),
    meta: { title: "姓名" },
  },
  {
    path: "/my-info-company",
    name: "/my-info-company",
    component: () => import("../views/user/MyInfoCompany.vue"),
    meta: { title: "所属渠道商" },
  },
  {
    path: "/my-selection-order",
    name: "/my-selection-order",
    component: () => import("../views/user/MySelectionOrder.vue"),
    meta: { title: "我的选品单" },
  },
  {
    path: "/my-selection-order-detail",
    name: "/my-selection-order-detail",
    component: () => import("../views/user/MySelectionOrderDetail.vue"),
    meta: { title: "选品单详情" },
  },
  {
    path: "/account-check",
    name: "/account-check",
    component: () => import("../views/login/AccountCheck.vue"),
    meta: { title: "登录/注册" },
  },
  {
    path: "/sign-in",
    name: "/sign-in",
    component: () => import("../views/login/SignIn.vue"),
    meta: { title: "登录" },
  },
  {
    path: "/sign-up",
    name: "/sign-up",
    component: () => import("../views/login/SignUp.vue"),
    meta: { title: "注册" },
  },
  {
    path: "/password-setting",
    name: "/password-setting",
    component: () => import("../views/login/PasswordSetting.vue"),
    meta: { title: "重置密码" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition);
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
