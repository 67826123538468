import request from "@/utils/request";
// 用户选品单

/**
 * 分页
 * @param {*} parameter
 * @returns
 */
export function userEnquiryPage(parameter) {
  return request({
    url: "/order/saleEnquiry/pageForUser",
    method: "get",
    params: parameter,
  });
}

/**
 * 详情
 * @param {*} parameter
 * @returns
 */
export function userEnquiryDetail(parameter) {
  return request({
    url: "/order/saleEnquiry/detail",
    method: "get",
    params: parameter,
  });
}
