import { defineStore } from "pinia";
import apis from "@/api";
import { showToast } from "@nutui/nutui";
import { ACCESS_TOKEN } from "@/config/constant";
import storage from "store";

const useUserStore = defineStore("user", {
  state: () => ({
    token: "",
    info: {},
  }),
  getters: {
    isLogined: (state) => !!state.token,
  },
  actions: {
    login(type, params) {
      return new Promise((resolve, reject) => {
        const loading = showToast.loading("", {
          duration: 0,
        });
        if (type === 1) {
          apis
            .userCodeLogin(params)
            .then((res) => {
              loading.hide();
              if (res.code === 200) {
                this.token = res.data.token;
                this.info = {
                  cellphone: params.cellphone,
                };
                storage.set(ACCESS_TOKEN, res.data.token);
                resolve(res);
              } else {
                showToast.fail(res.msg);
                reject(res);
              }
            })
            .catch((err) => {
              loading.hide();
              reject(err);
            });
        } else if (type === 2) {
          apis
            .userPasswordLogin(params)
            .then((res) => {
              loading.hide();
              if (res.code === 200) {
                this.token = res.data.token;
                this.info = {
                  cellphone: params.cellphone,
                };
                storage.set(ACCESS_TOKEN, res.data.token);
                resolve(res);
              } else {
                showToast.fail(res.msg);
                reject(res);
              }
            })
            .catch((err) => {
              loading.hide();
              reject(err);
            });
        }
      });
    },
    register(params) {
      return new Promise((resolve, reject) => {
        const loading = showToast.loading("", {
          duration: 0,
        });
        apis
          .userRegister(params)
          .then((res) => {
            loading.hide();
            if (res.code === 200) {
              this.token = res.data.token;
              this.info = {
                cellphone: params.cellphone,
              };
              storage.set(ACCESS_TOKEN, res.data.token);
              resolve(res);
            } else {
              showToast.fail(res.msg);
              reject(res);
            }
          })
          .catch((err) => {
            loading.hide();
            reject(err);
          });
      });
    },
    getLocalInfo() {
      const token = storage.get(ACCESS_TOKEN);
      this.token = token;
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        apis
          .userInfo()
          .then((res) => {
            if (res.code === 200) {
              this.info = res.data || {};
              resolve(res);
            } else {
              showToast.fail(res.msg);
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    quit(type) {
      return new Promise((resolve) => {
        const logout = () => {
          this.token = "";
          this.info = {};
          storage.remove(ACCESS_TOKEN);
          resolve();
        };
        if (type === "onlyDeleteCache") {
          logout();
        } else {
          const loading = showToast.loading("正在退出...", {
            duration: 0,
          });
          apis
            .adminLogout()
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              loading.hide();
              logout();
            });
        }
      });
    },
    RefreshToken() {
      const token = storage.get(ACCESS_TOKEN);
      if (!token) return;
      return new Promise((resolve, reject) => {
        try {
          // console.log('token', token)
          let decodeToken = window.atob(token.split(".")[1]);
          // console.log("decodeToken", decodeToken);
          let user = decodeURIComponent(decodeToken); // 将JWT切割   获取需要的载荷
          let userInfo = JSON.parse(user);
          // console.log(userInfo);
          let exp = (userInfo.exp || 0) * 1000;

          // token 还有 2小时过期
          if (exp - Date.now() < 2 * 60 * 60 * 1000) {
            apis
              .refreshToken()
              .then((res) => {
                console.log(res);
                if (res.code === 200) {
                  this.token = res.data;
                  storage.set(ACCESS_TOKEN, res.data);
                  resolve();
                } else {
                  reject(res);
                }
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          } else {
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
});

export default useUserStore;
