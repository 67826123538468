import request from "@/utils/request";
// 用户购物车

/**
 * 分页
 * @param {*} parameter
 * @returns
 */
export function userCartPage(parameter) {
  return request({
    url: "/user/userCart/page",
    method: "get",
    params: parameter,
  });
}

/**
 * 加入购物车
 * @param {*} parameter
 * @returns
 */
export function userCartAdd(parameter) {
  return request({
    url: "/user/userCart/add",
    method: "post",
    data: parameter,
  });
}

/**
 * 批量删除
 * @param {*} parameter
 * @returns
 */
export function userCartDelete(parameter) {
  return request({
    url: "/user/userCart/delete",
    method: "post",
    params: parameter,
  });
}

/**
 * 用户提交购物车
 * @param {*} parameter
 * @returns
 */
export function userCartSubmit(parameter) {
  return request({
    url: "/user/userCart/submit",
    method: "post",
    data: parameter,
  });
}

/**
 * 修改数量
 * @param {*} parameter
 * @returns
 */
export function userCartUpdateQuantity(parameter) {
  return request({
    url: "/user/userCart/updateQuantity",
    method: "post",
    data: parameter,
  });
}
