const keys = {
  dev: {
    accessKey: "lX3dB1iL9kG1lX2f", // java接口accessKey
    secretKey: "tQ6rH9rN9yA8yO7vE7rH8tD0uN4xV3qZ", // java接口secretKey
    imageSecurityKey: "vLZo4cqxmhA5klyWyXW5", // 图片服务安全key
    amapWebServiceKey: "45a04e1cebb9cba0407f7d48f52ffafc", // 高德地图web服务key
    amapWebEndKey: "34989755d5440a8f63a476c78700d2d5", // 高德地图web端key(jsapi)
    securityJsCode: "01d0116e820d27ffeea89e454fd787b8", // 高德地图jscode安全密钥
  },
  test: {
    accessKey: "lX3dB1iL9kG1lX2f", // java接口accessKey
    secretKey: "tQ6rH9rN9yA8yO7vE7rH8tD0uN4xV3qZ", // java接口secretKey
    imageSecurityKey: "tqZcNGVyGdVPpQmrqzrD", // 图片服务安全key
    amapWebServiceKey: "45a04e1cebb9cba0407f7d48f52ffafc", // 高德地图web服务key
    amapWebEndKey: "34989755d5440a8f63a476c78700d2d5", // 高德地图web端key(jsapi)
    securityJsCode: "01d0116e820d27ffeea89e454fd787b8", // 高德地图jscode安全密钥
  },
  prod: {
    accessKey: "lX3dB1iL9kG1lX2f", // java接口accessKey
    secretKey: "tQ6rH9rN9yA8yO7vE7rH8tD0uN4xV3qZ", // java接口secretKey
    imageSecurityKey: "xjwXvByGleYjRZOuu5dO", // 图片服务安全key
    amapWebServiceKey: "45a04e1cebb9cba0407f7d48f52ffafc", // 高德地图web服务key
    amapWebEndKey: "34989755d5440a8f63a476c78700d2d5", // 高德地图web端key(jsapi)
    securityJsCode: "01d0116e820d27ffeea89e454fd787b8", // 高德地图jscode安全密钥
  },
};

export default keys[process.env.VUE_APP_API_ENV];
